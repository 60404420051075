import SchoolRepository from '@/shared/http/repositories/socialProject/school'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewRegisterSchool from '@/components/layout/NewRegisterSchool/newRegisterSchool.vue'
import School from '@/shared/models/school'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'EditarEscola',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewRegisterSchool.name]: NewRegisterSchool
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Escolas',
    school: new School(),
    isNew: false
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Escolas',
            href: this.$router.resolve({ name: 'School' }).href
          },
          {
            text: 'Editar escola',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getById()
  },


  methods: {
    getById() {
      loading.push()
      SchoolRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.school = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar escola', 'ERRO')
        })
    },

    update(school) {
      loading.push()
      SchoolRepository.Update(school)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Escola editada com sucesso', 'EDITAR ESCOLA')
          Promise.resolve()
          return this.$router.replace({ name: 'School' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar escola', 'ERRO')
        })
    },

    clearCity(){
      this.school.cityID = null
    }
  }
}
